<template>
    <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="6">
                        <h3>Participantes</h3>
                    </vs-col>
                    <vs-col vs-offset="5" vs-lg="1" vs-align="flex-end">
                        <vs-button @click="nuevo" color="success" type="relief">Agregar Nuevo</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="12">
                        
                            <div slot="header">
                                <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="card-title">Participantes</h5>
                                    <h6 class="card-subtitle mb-0">Todos los participantes, ya sean de seccion especial o de concurso</h6>
                                </div>
                                </div>
                            </div>
                            <VityTable 
                                v-if="url"
                                :columns="columns"
                                :url="url+'participants'"
                                :params="{admin: 1}"
                                :orderBy="{
                                    column: 0,
                                    type: 1
                                }"
                            />
                    
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card v-if="participante">
                <h4 class="card-title d-flex">
                    Participante {{participante.name}} {{participante.lastname}}
                </h4>
                <p class="card-subtitle">
                    Aqui podras cambiar toda la informacion del participante
                </p>
                
                <vs-row vs-justify="center">
                    <vs-col vs-lg="2" vs-xs="2">
                        <label for="" class="mr-2 mb-0">Activo</label>
                        <vs-switch color="success" v-model="participante.active"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input maxlength="50" label="Nombre" class="inputx" placeholder="Nombre" v-model="participante.name"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input maxlength="50" label="Apellidos" class="inputx" placeholder="Apellidos" v-model="participante.lastname"/>
                    </vs-col>
                    <vs-col vs-lg="12" vs-xs="12">
                        <br>
                        <label>Descripcion</label>
                        <vs-textarea rows="3" counter="255" label="Counter: 255" :counter-danger.sync="descripcionDanger" v-model="participante.description" />
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <input type="file" @change="cambiarFoto" />
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12" style="max-height: 198px">
                        <img style="display: block;min-width: 100%;width: 100%;min-height: 100%;height: 100%;" v-show="participante.img" :src="participante.img"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="DNI" class="inputx" maxlength="9" placeholder="DNI" v-model="participante.dni"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input maxlength="50" type="email" label="Email" class="inputx" placeholder="Email" v-model="participante.email"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input maxlength="25" type="tel" label="Telefono" class="inputx" placeholder="Telefono" v-model="participante.phone"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Creado el" class="inputx" placeholder="Creado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', participante.created_at, true)" disabled/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Actualizado el" class="inputx" placeholder="Actualizado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', participante.updated_at, true)" disabled/>
                    </vs-col>
                    <br>
                    <vs-col vs-lg="12" vs-xs="12">
                        <vs-button @click="save" color="success" type="relief">Guardar</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card v-if="participante && participante.id">
                <vs-row vs-justify="center">
                    <vs-col vs-lg="12">
                        
                            <div slot="header">
                                <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="card-title">Participantes</h5>
                                    <h6 class="card-subtitle mb-0">Todos los participantes, ya sean de seccion especial o de concurso</h6>
                                </div>
                                </div>
                            </div>
                            <VityTable
                                :columns="columnsObras"
                                :url="url+'works?participant='+participante.id"
                                :params="{admin: 1}"
                                :orderBy="{
                                    column: 0,
                                    type: 1
                                }"
                            />
                    
                    </vs-col>
                </vs-row>
            </vs-card>
        </vs-col>
    </vs-row>
</template>

<script>
import axios from 'axios'
import VityTable from "@/components/VityTable.vue";

export default {
    name: 'Participantes',
    components: {
        VityTable
    },
    data: function(){
        return{
            columns:[
                {
                    name: 'ID',
                    dbName: 'id',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Nombre',
                    dbName: 'name',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Apellidos',
                    dbName: 'lastname',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Email',
                    dbName: 'email',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Telefono',
                    dbName: 'phone',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Accion',
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/participantes/TPLData">EDITAR</a>',
                    reference: 'id'
                },
            ],
            columnsObras: [
                {
                    name: 'ID',
                    dbName: 'id',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Titulo',
                    dbName: 'title',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Categoria',
                    dbName: 'category.name',
                    orderable: false,
                    searchable: false
                },
                {
                    name: 'Edicion',
                    dbName: 'edition',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Accion',
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/trabajos/TPLData">VER</a>',
                    reference: 'id'
                },
            ],
            url: process.env.VUE_APP_URL,
            participante: null,
            descripcionDanger: false,
            required: [
                {
                    name: 'name',
                    viewName: 'Nombre'
                }
            ],
            nuevaImagen: null
        } 
    },
    methods:{
        getParticipante: function(id){
            if(!id)
                this.participant = null;
            else{
                axios
                    .get(process.env.VUE_APP_URL+'participants/'+id, {
                        params:{
                            admin: 1
                        }
                    })
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.participante = rs.data;
                            this.participante.active = this.participante.active ? true : false;
                        }
                        else{
                            this.participante = null;
                            this.$parent.$parent.errors = ['Participante no encontrado'];
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$parent.$parent.errors = ['Fallo al conectar'];
                    });
            }
        },
        save: function(){
            if(this.$parent.$parent.checkParams(this.required, this.participante))
                return;

            var params = new FormData();
            params.append('active', this.participante.active ? 1 : 0);
            params.append('name', this.participante.name);
            if(this.participante.lastname)
                params.append('lastname', this.participante.lastname);
            if(this.participante.description)
                params.append('description', this.participante.description);
            if(this.nuevaImagen)
                params.append('img', this.nuevaImagen);
            if(this.participante.dni)
                params.append('dni', this.participante.dni);
            if(this.participante.email)
                params.append('email', this.participante.email);
            if(this.participante.phone)
                params.append('phone', this.participante.phone);

            /*let params = {
                active:         this.participante.active ? 1 : 0,
                name:           this.participante.name,
                lastname:       this.participante.lastname,
                description:    this.participante.description,
                img:            this.nuevaImagen ? this.nuevaImagen : null,
                dni:            this.participante.dni,
                email:          this.participante.email,
                phone:          this.participante.phone,
            };*/

            if(this.participante.id){
                axios
                    .post(process.env.VUE_APP_URL+'participants/'+this.participante.id, params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.getParticipante(this.$route.params.id);
                            //this.$router.push('participantes/'+rs.data);
                            this.$parent.$parent.aciertos = ['Guardado correctamente'];
                        }
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        this.$parent.$parent.errors = ['Hubo un problema al conectar'];
                    });
            }
            else{
                axios
                    .post(process.env.VUE_APP_URL+'participants', params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK')
                            this.$router.push('/participantes/'+rs.data);
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        let msg = 'Hubo un problema al conectar';
                        if(error.response && error.response.data && error.response.data.message)
                            msg = error.response.data.message;
                        this.$parent.$parent.errors = [msg];
                    });
            }
        },
        nuevo: function(){
            this.$router.push('/participantes/new');
        },
        cambiarFoto: function(data){
            this.nuevaImagen = data.target.files[0];
        }
    },
    created: function(){
        if(this.$route.params.id == 'new')
            this.participante = {};
        else
		    this.getParticipante(this.$route.params.id);
	},
	beforeRouteUpdate (to, from, next) {
        if(to.params.id == 'new')
            this.participante = {};
        else
		    this.getParticipante(to.params.id);
		next();
	}
}
</script>